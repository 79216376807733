import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { onRegisterHook } from "../global/Hooks";

const ConfirmPassword = () => {
  const [activated, setActivated] = useState(false);
  const { confirmid } = useParams();

  const fetchData = async () => {
    const data = await window.TALKVIOAPI("confirmRegistation", { confirmid });
    window.displayError(data);
    setActivated(data.success);
    if (data.registration) {
      onRegisterHook();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    activated && (
      <div className="normalBackground">
        <div className="specialPage">
          <div className="confirm">
            {__("Your account is activated now")}.{" "}
            {__(
              "You need to login into your account with the recently created credentials",
            )}
            .
          </div>
        </div>
      </div>
    )
  );
};
export default ConfirmPassword;
