import { useEffect, useLayoutEffect, useRef } from "react";

function getDistance(touch1, touch2) {
  const dx = touch2.clientX - touch1.clientX;
  const dy = touch2.clientY - touch1.clientY;
  return Math.sqrt(dx * dx + dy * dy);
}

export default ({ src, description, descriptionAlt, height, width }) => {
  const imageContainer = useRef();
  const imageRef = useRef();

  let panning = false;
  let scale = 1;
  let pointX = 0;
  let pointY = 0;
  let startX = 0;
  let startY = 0;
  let initialTouchDistance = 0;
  let initialTouchScale = 0;

  let mouseMoved = false;

  function setTransform() {
    imageRef.current.style.transform =
      "translate(" + pointX + "px, " + pointY + "px) scale(" + scale + ")";
  }

  const mouseDown = (e) => {
    e.preventDefault();
    startX = e.clientX - pointX;
    startY = e.clientY - pointY;
    panning = true;

    mouseMoved = false;
  };

  const mouseUp = () => {
    panning = false;
  };

  const wheel = (e) => {
    e.preventDefault();
    let delta = e.wheelDelta ? e.wheelDelta : -e.deltaY;
    if (delta > 0) scale *= 1.15;
    else scale /= 1.15;
    setTransform();
  };

  const mouseMove = (e) => {
    e.preventDefault();
    if (!panning) {
      return;
    }
    pointX = e.clientX - startX;
    pointY = e.clientY - startY;
    setTransform();

    mouseMoved = true;
  };

  const mouseOutScreen = () => {
    logT("zoom", "mouse out of screen");
    panning = false;
  };

  const touchStart = (e) => {
    logT("zoomTouch", "start", "pointers", e.touches.length);
    e.preventDefault();
    panning = true;
    if (e.touches.length === 2) {
      initialTouchScale = scale;
      initialTouchDistance = getDistance(e.touches[0], e.touches[1]);
    } else if (e.touches.length === 1) {
      initialTouchScale = scale;
      startX = e.touches[0].clientX - pointX;
      startY = e.touches[0].clientY - pointY;
    }
  };

  const touchMove = (e) => {
    if (e.touches.length == 2) {
      e.preventDefault();
      const currentTouchDistance = getDistance(e.touches[0], e.touches[1]);
      if (initialTouchDistance > 0) {
        let touchScale = currentTouchDistance / initialTouchDistance;
        scale = initialTouchScale * touchScale;
        setTransform();
      }
    } else if (e.touches.length == 1) {
      e.preventDefault();
      pointX = e.touches[0].clientX - startX;
      pointY = e.touches[0].clientY - startY;
      setTransform();
      mouseMoved = true;
    }
  };

  const touchEnd = (e) => {
    logT("zoomTouch", "end", "pointers", e.touches.length);
    panning = false;
    if (e.touches.length == 0 && initialTouchScale == scale && !mouseMoved) {
      logT("zoomTouch", "end", "close image", mouseMoved);
      postZoomActions();
    }
  };

  const postZoomActions = (overlay = false) => {
    if (!imageContainer?.current) {
      return;
    }

    if (imageContainer.current.classList.contains("zoomed")) {
      if (mouseMoved && !overlay) {
        return;
      }

      logT("zoom", "stop image zoom");

      document.body.style.overflow = "initial";
      pointX = 0;
      pointY = 0;
      scale = 1;
      setTransform();
      imageRef.current.style.transform = "initial";
      imageRef.current.removeEventListener("mousedown", mouseDown);
      imageRef.current.removeEventListener("mouseup", mouseUp);
      imageRef.current.removeEventListener("wheel", wheel);
      imageRef.current.removeEventListener("mousemove", mouseMove);
      imageRef.current.removeEventListener("touchstart", touchStart);
      imageRef.current.removeEventListener("touchmove", touchMove);
      imageRef.current.removeEventListener("touchend", touchEnd);
      document.removeEventListener("mouseleave", mouseOutScreen);
      if (typeof window.TalkvioAndroid != "undefined") {
        window.TalkvioAndroid.setPageScrollReload(true);
      }
      window.unblockHeader();
    } else {
      if (overlay) {
        return;
      }

      logT("zoom", "start image zoom");

      document.body.style.overflow = "hidden";
      imageRef.current.addEventListener("mousedown", mouseDown);
      imageRef.current.addEventListener("mouseup", mouseUp);
      imageRef.current.addEventListener("wheel", wheel);
      imageRef.current.addEventListener("mousemove", mouseMove);
      imageRef.current.addEventListener("touchstart", touchStart);
      imageRef.current.addEventListener("touchmove", touchMove);
      imageRef.current.addEventListener("touchend", touchEnd);
      document.addEventListener("mouseleave", mouseOutScreen);

      mouseMoved = false;
      initialTouchScale = 0;

      const naturalWidth = imageRef.current.naturalWidth;
      const naturalHeight = imageRef.current.naturalHeight;
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      const screenScale = windowWidth > 850 ? 0.8 : 1;

      const scaleWidth = (windowWidth / naturalWidth) * screenScale;
      const scaleHeight = (windowHeight / naturalHeight) * screenScale;
      scale = Math.min(scaleWidth, scaleHeight);

      pointX =
        -(naturalWidth - naturalWidth * scale) / 2 +
        (windowWidth - naturalWidth * scale) / 2;
      pointY =
        -(naturalHeight - naturalHeight * scale) / 2 +
        (windowHeight - naturalHeight * scale) / 2;

      setTransform();
      if (typeof window.TalkvioAndroid != "undefined") {
        window.TalkvioAndroid.setPageScrollReload(false);
      }
      window.blockHeader();
    }
    imageContainer.current.classList.toggle("zoomed");
  };

  useLayoutEffect(() => {
    return () => {
      postZoomActions(true);
    };
  }, []);

  return (
    <div
      ref={imageContainer}
      className="imageContainer"
      itemProp="image"
      itemScope
      itemType="http://schema.org/ImageObject"
    >
      <img
        height={height}
        width={width}
        ref={imageRef}
        src={src}
        className="zoomImage"
        alt={descriptionAlt}
        itemProp="contentUrl"
        onClick={() => {
          postZoomActions();
        }}
      />
      {description ? (
        <figcaption itemProp="caption" className="imageDescription">
          {description}
        </figcaption>
      ) : null}
      <meta itemProp="creditText" content={descriptionAlt} />
      <div
        className="overlay"
        onClick={() => {
          postZoomActions(true);
        }}
      ></div>
    </div>
  );
};
