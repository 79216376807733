import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const Search = ({ onSeaching }) => {
  const searchPanel = useRef();
  const searchEl = useRef();
  const navigate = useNavigate();
  let clearEnterTimeouts = [];
  let currentlyInsideElement = false;

  const search = (text, force = false) => {
    if (!searchPanel.current.classList.contains("enter") && !force) {
      enterElement();
      return;
    }
    if (text.length == 0) return;
    navigate("/search", { state: { search: text } });
    searchEl.current.value = "";
    clearElement();
  };

  const enterElement = () => {
    searchPanel.current.classList.add("enter");
    searchPanel.current.focus();
    if (searchEl.current.offsetWidth < 100)
      setTimeout(() => searchEl.current.focus(), 0);
    if (onSeaching) onSeaching(true);
    if (clearEnterTimeouts && clearEnterTimeouts.length > 0) {
      clearEnterTimeouts.forEach((t) => clearTimeout(t));
      clearEnterTimeouts.splice(0, clearEnterTimeouts.length);
    }
  };

  const clearElement = () => {
    searchPanel.current.classList.remove("enter");
    if (onSeaching) onSeaching(false);
  };

  const leaveElement = (timeout = 1000) => {
    if (searchEl.current.value.length > 0) return;
    clearEnterTimeouts.push(
      setTimeout(() => {
        if (searchEl.current.value.length > 0) return;
        clearElement();
      }, timeout),
    );
  };

  useEffect(() => {
    if (!window.$) return;
    window.$(searchPanel.current).hover(
      () => {
        currentlyInsideElement = true;
        enterElement();
      },
      () => {
        currentlyInsideElement = false;
        leaveElement();
      },
    );
  }, []);

  return (
    <div ref={searchPanel} className="searchPanel">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          search(searchEl.current.value);
        }}
      >
        <input
          onInput={() => {
            if (currentlyInsideElement) return;
            if (searchEl.current.value.length == 0) {
              leaveElement(4000);
            }
          }}
          ref={searchEl}
          autoComplete="off"
          placeholder={__("Search")}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              search(searchEl.current.value, true);
              e.preventDefault();
            }
          }}
        ></input>
      </form>
      <div
        className="searchButton"
        onClick={async () => {
          search(searchEl.current.value);
        }}
      >
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </div>
    </div>
  );
};
export default Search;
