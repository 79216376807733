import React, { useState, useEffect, createContext, useContext } from "react";
import md5 from "md5";
import { eraseCookie, getCookie, setCookie } from "../global/Global";
import { onLoadSettings, onRegisterHook } from "../global/Hooks";
import { ThemeContext } from "./Theme";

export const LoginContext = createContext();

const Login = ({ children }) => {
  const [user, setUser] = useState();
  const { setDarkTheme, resetDarkTheme, darkMode } = useContext(ThemeContext);

  const token = getCookie("token");
  const userid = parseInt(getCookie("userid"));
  const [possiblePreLogin, setPossiblePreLogin] = useState(
    userid && userid > 0 && token && token.length > 0,
  );

  if (userid && userid > 0 && token && token.length > 0) {
    window.needLogin = false;
  } else {
    window.needLogin = true;
  }

  const sessionOptions = {
    android: typeof window.TalkvioAndroid != "undefined",
  };

  const fetchData = async (ignoreSession = false) => {
    if (userid && userid > 0 && token && token.length > 0) {
      logT("login", "token", token, "ignore old session = " + ignoreSession);
      setPossiblePreLogin(true);

      const data = await window.TALKVIOAPI("loginToken", {
        userid,
        token,
        ignoreSession: ignoreSession,
        options: sessionOptions,
      });
      if (data.success) {
        setUser(data.user);
        onLoadSettings(data.user);
        if (
          typeof data.user.settings?.darkMode != "undefined" &&
          ((data.user.settings?.darkMode && !window.darkMode) ||
            (!data.user.settings?.darkMode && window.darkMode))
        ) {
          logT(
            "theme",
            "changed darkmode from user settings =",
            data.user.settings.darkMode,
          );
          if (data.user.settings.darkMode)
            setDarkTheme(data.user.settings.darkMode);
          else resetDarkTheme();
        }
      } else {
        setPossiblePreLogin(false);
        // cookies set, need to unset them
        if (data.error) {
          logTE("login", "login info incorrect, unset cookies");
          eraseCookie("userid");
          eraseCookie("token");
          eraseCookie("darkmode");
          if (
            typeof window.TalkvioAndroid != "undefined" &&
            window.TalkvioAndroid.logOutPerformed
          ) {
            window.TalkvioAndroid.logOutPerformed();
          }
          window.location.href = window.location.href;
        }
      }
    }
  };

  const reloadUserInfo = () => {
    logT("login", "reload user info");
    fetchData(true);
  };

  window.TALKVIO_ON(
    "notification",
    (sub) => {
      if (sub.userid == user?.userid) {
        const newUserObject = Object.assign({}, user);
        newUserObject.notifications++;
        setUser(newUserObject);
      }
    },
    "login",
  );

  window.TALKVIO_ON(
    "readNotification",
    (sub) => {
      if (sub.userid == user?.userid) {
        const newUserObject = Object.assign({}, user);
        newUserObject.notifications--;
        setUser(newUserObject);
      }
    },
    "login",
  );

  window.TALKVIO_ON(
    "readAllNotifications",
    (sub) => {
      if (sub.userid == user?.userid) {
        const newUserObject = Object.assign({}, user);
        newUserObject.notifications = 0;
        setUser(newUserObject);
      }
    },
    "login",
  );

  window.TALKVIO_ON(
    "changedSettings",
    (u) => {
      if (u.userid == user?.userid) {
        logT("login", "user settings changed, reload user info");
        reloadUserInfo();
      }
    },
    "login",
  );

  window.TALKVIO_ON(
    "blockTag",
    (u) => {
      if (u.userid == user?.userid) {
        logT("socket", "tagBlock", u.userid);
        reloadUserInfo();
      }
    },
    "login",
  );

  const doLogin = async ({
    login,
    password,
    credential,
    facebookToken,
    vkCode,
  }) => {
    let data;

    const loginOptions = {
      contester: !!getCookie("contester"),
    };

    if (credential) {
      data = await window.TALKVIOAPI("loginGoogle", {
        credential,
        options: Object.assign(loginOptions, sessionOptions),
      });
    } else if (facebookToken) {
      data = await window.TALKVIOAPI("loginFacebook", {
        facebookToken,
        options: Object.assign(loginOptions, sessionOptions),
      });
    } else if (vkCode) {
      data = await window.TALKVIOAPI("loginVK", {
        vkCode,
        options: Object.assign(loginOptions, sessionOptions),
      });
    } else {
      data = await window.TALKVIOAPI("login", {
        email: login,
        password: md5(password),
        options: Object.assign(loginOptions, sessionOptions),
      });
    }
    window.displayError(data);
    if (data.success) {
      setUser(data.user);
      setCookie("token", data.token, 180);
      setCookie("userid", data.user.userid, 180);
      if (
        typeof window.TalkvioAndroid != "undefined" &&
        window.TalkvioAndroid.loginPerformed
      ) {
        window.TalkvioAndroid.loginPerformed(data.user.userid, data.token);
      }
      if (
        typeof data.user.settings?.darkMode != "undefined" &&
        ((data.user.settings?.darkMode && !getCookie("darkmode")) ||
          (!data.user.settings?.darkMode && getCookie("darkmode")))
      ) {
        logT(
          "theme",
          "changed darkmode from user settings =",
          data.user.settings.darkMode,
        );
        if (data.user.settings.darkMode)
          setDarkTheme(data.user.settings.darkMode);
        else resetDarkTheme();
      }
      if (data.registration) {
        onRegisterHook();
      }
    }
  };
  // google only
  window.performLogin = ({ credential }) => {
    doLogin({ credential });
  };

  const doRegistation = async ({ login, password, username, token }) => {
    const data = await window.TALKVIOAPI("register", {
      email: login,
      username,
      password: md5(password),
      token,
      options: {
        contester: !!getCookie("contester"),
      },
    });
    window.displayError(data);
    if (data.success) {
      window.showOk(
        __("Confirmation"),
        __("Confirmation sended to your email") + ": " + login,
      );
      return true;
    }
    return false;
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <LoginContext.Provider
      value={{
        user,
        doLogin,
        possiblePreLogin,
        doRegistation,
        reloadUserInfo,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};
export default Login;
