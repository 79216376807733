import "./App.css";
import { useEffect, useLayoutEffect, useRef } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import Forums from "./components/Forums.js";
import Threads from "./components/Threads.js";
import Posts from "./components/Posts.js";
import Login from "./components/Login.js";
import RestorePassword from "./components/RestorePassword";
import ScreenMessage from "./components/ScreenMessage";
import { Link } from "react-router-dom";
import ConfirmPassword from "./components/ConfirmPassword";
import Activity from "./components/Activity";
import Popular from "./components/Popular";
import Footer from "./components/Footer";
import User from "./components/User";
import Header from "./components/Header";
import {
  getNoficiationsPermission,
  sendNotification,
} from "./global/Notifications";
import Users from "./components/Users";
import SpecialPage from "./components/SpecialPage";
import SearchPage from "./components/SearchPage";
import Translations from "./components/Translations";
import { getCookie, setCookie } from "./global/Global";
import Subscriptions from "./components/Subscriptions";
import Original from "./components/Original";
import DelayedPosts from "./components/DelayedPosts";
import Main from "./components/Main";
import VKLogin from "./components/VKLogin";
import MostCommented from "./components/MostCommented";
import Tag from "./components/Tag";
import useDidUpdateEffect from "./global/DidUpdateEffect";
import Threme from "./components/Theme";
import Bookmarks from "./components/Bookmarks";
import SettingsPage from "./components/SettingsPage";
import NotFoundPage from "./components/NotFoundPage";
import ConfirmMessage from "./components/ConfirmMessage";
import Random from "./components/Random";
import RemoveAccount from "./components/RemoveAccount";
import Answers from "./components/Answers";
import MyComments from "./components/MyComments";
import RightPanel from "./components/RightPanel.js";
import Keys from "./components/Keys.js";
import LeftPanel from "./components/LeftPanel.js";
import Section from "./components/Section.js";
import Overlay from "./components/Overlay.js";

window.loadingFinished = -1;
window.loadingStart = () => {
  if (window.loadingFinished == -1)
    window.loadingFinished = window.loadingFinished + 2;
  else window.loadingFinished++;

  logT("loading", "start", window.loadingFinished);
};
window.loadingFinish = () => {
  window.loadingFinished--;

  logT("loading", "finish", window.loadingFinished);
};

const socket = io();

socket.on("connect", () => {
  if (socket.recovered) {
    logT("socket", "socket reconnected");
  } else {
    logT("socket", "socket connected");
  }
});

window.TAKVIO_API_TAGS = {};

window.TALKVIOAPI = (apiPoint, data, options = {}) =>
  new Promise(async (resolve) => {
    window.loadingStart();
    logT(
      "api",
      apiPoint + (options.mark ? ` (${options.mark})` : ""),
      "transport",
      socket?.io?.engine?.transport?.name,
      data,
    );
    if (options?.before) options.before();
    let id;
    if (options.tag) {
      if (!window.TAKVIO_API_TAGS[options.tag]) {
        window.TAKVIO_API_TAGS[options.tag] = [];
      }
      id =
        (Math.random() + 1).toString(36).substring(7) +
        (Math.random() + 1).toString(36).substring(7);
      window.TAKVIO_API_TAGS[options.tag].push(id);
    }

    let chunkId;
    if (options.chunkMode) {
      chunkId =
        (Math.random() + 1).toString(36).substring(7) +
        (Math.random() + 1).toString(36).substring(7);
      const chunkSpliSize = 4 * 1024 * 1024;
      let chunksData = new TextEncoder().encode(JSON.stringify(data));
      data = null;
      let chunkSize = chunksData.length;
      const chunks = [];
      let sindex = 0;
      while (sindex < chunkSize) {
        const chunk = chunksData.subarray(sindex, sindex + chunkSpliSize);
        chunks.push(chunk);
        sindex += chunk.length;
      }
      chunksData = null;
      logT(
        "chunk",
        "sending request by chunks",
        chunkId,
        "chunks number:",
        chunks.length,
        "size:",
        chunkSize,
      );
      for (let chunkIndex = 0; chunkIndex < chunks.length; chunkIndex++) {
        const chunk = chunks[chunkIndex];
        await new Promise((resolve) => {
          socket.emit(
            apiPoint,
            {
              chunkMode: true,
              chunkId,
              chunkIndex,
              chunkSize,
              chunkEachSize: chunkSpliSize,
              chunk,
            },
            () => {
              logT("chunk", "responce from", chunkIndex, "chunk id", chunkId);
              resolve();
            },
          );
        });
      }
      // await Promise.all(chunks.map((chunk, chunkIndex) => new Promise((resolve) => {
      //   socket.emit(apiPoint, {
      //     chunkMode: true,
      //     chunkId,
      //     chunkIndex,
      //     chunkSize,
      //     chunkEachSize: chunkSpliSize,
      //     chunk,
      //   }, (responce) => {
      //       logT('chunk', 'responce from', chunkIndex, 'chunk id', chunkId)
      //       resolve();
      //   })
      // })))
    }

    socket.emit(
      apiPoint,
      !options.chunkMode ? data : { chunkId, chunkMode: true },
      (responce) => {
        logT(
          "api",
          apiPoint + (options.mark ? ` (${options.mark})` : ""),
          "responce",
          responce,
        );
        if (options.tag) {
          let index = window.TAKVIO_API_TAGS[options.tag]?.indexOf(id);
          if (typeof index == "number" && index >= 0) {
            window.TAKVIO_API_TAGS[options.tag].splice(index, 1);
            if (window.TAKVIO_API_TAGS[options.tag].length == 0) {
              delete window.TAKVIO_API_TAGS[options.tag];
            }
          } else {
            logT(
              "api",
              apiPoint + (options.mark ? ` (${options.mark})` : ""),
              "request cancel",
            );
            responce = null;
          }
        }
        resolve(responce);
        if (options?.after) options.after(responce);
        window.loadingFinish();
      },
    );
  });

window.TALKVIOAPI_CANCEL = (tag) => {
  if (window.TAKVIO_API_TAGS[tag]) {
    delete window.TAKVIO_API_TAGS[tag];
    logT("api", "cancle api tag", tag);
  }
};

window.TALKVIO_ID_MAP = {};

window.TALKVIO_ON = (apiPoint, callback, id = null) => {
  if (!id) {
    return socket.on(apiPoint, callback);
  } else {
    if (!window.TALKVIO_ID_MAP[id + "_" + apiPoint]) {
      window.TALKVIO_ID_MAP[id + "_" + apiPoint] = callback;
      return socket.on(apiPoint, callback);
    } else {
      socket.off(apiPoint, window.TALKVIO_ID_MAP[id + "_" + apiPoint]);
      window.TALKVIO_ID_MAP[id + "_" + apiPoint] = callback;
      return socket.on(apiPoint, callback);
    }
  }
};
window.isTalkvioWebsocket = () =>
  socket?.io?.engine?.transport?.name == "websocket";

window._app_on_map = {};
window.APP_ON = (apiPoint, callback) => {
  if (!window._app_on_map[apiPoint]) window._app_on_map[apiPoint] = [];

  window._app_on_map[apiPoint].push(callback);
};
window.appJS = (apiPoint, data) => {
  if (
    !window._app_on_map[apiPoint] ||
    window._app_on_map[apiPoint].length == 0
  ) {
    return;
  }
  logT("appAPI", "call", apiPoint, "with data", data);
  for (const callback of window._app_on_map[apiPoint]) {
    callback(data);
  }
};
window.APP_OFF = (apiPoint, callback) => {
  if (!window._app_on_map[apiPoint]) {
    logTW("appAPI", "no app off point", apiPoint);
    return;
  }

  if (!callback) {
    delete window._app_on_map[apiPoint];
    return;
  }

  const index = window._app_on_map[apiPoint].indexOf(callback);
  if (index > -1) {
    window._app_on_map[apiPoint].splice(index, 1);
    if (window._app_on_map[apiPoint].length == 0) {
      delete window._app_on_map[apiPoint];
    }
  } else {
    logTW("appAPI", "no callback func to cancel");
  }
};

function App() {
  window.loadingStart();
  const location = useLocation();

  let isCurrentBackward = useRef(false);
  useDidUpdateEffect(() => {
    setTimeout(() => {
      logT(
        "location",
        "change location",
        "location =",
        location.pathname,
        "key =",
        location.key,
        "backward =",
        isCurrentBackward.current,
      );
      const locationEvent = new CustomEvent("talkvioLocationChange", {
        detail: { backward: isCurrentBackward.current },
      });
      isCurrentBackward.current = false;
      window.dispatchEvent(locationEvent);
    }, 0);
  }, [location]);

  useEffect(() => {
    window.addEventListener("popstate", () => {
      isCurrentBackward.current = true;
    });

    const myUserid = parseInt(getCookie("userid"));
    if (myUserid && myUserid > 0) {
      getNoficiationsPermission();
      window.TALKVIO_ON(
        "notification",
        ({ userid, sourceuserid, post }) => {
          if (post.readableText.includes("#nodgx")) {
            return;
          }

          const myUserid = parseInt(getCookie("userid"));
          if (!myUserid || myUserid != userid) {
            return;
          }

          sendNotification({
            title: __("New post from") + " " + post.username,
            message: post.readableText,
            icon:
              post.avatarrevision > 0
                ? `https://talkvio.com/customavatars/avatar${post.userid}_${post.avatarrevision}.gif`
                : null,
          });
        },
        "notifications",
      );
    }

    if (getCookie("screenlog")) {
      logT("console", "lgs on (show screen console)");
      document.getElementById("lgses").style.display = "block";
    }
  }, []);

  useLayoutEffect(() => {
    setTimeout(() => {
      window.loadingFinish();
    }, 50);
  });

  if (getCookie("screenlog")) {
    logT("console", "lgs redirect logging to screen");
    window.screenLogs = true;
  }

  return (
    <div className="Talkvio">
      <Keys>
        <Threme>
          <Translations>
            <Login>
              <Header />
              <div className="pagecontent">
                <ScreenMessage />
                <ConfirmMessage />
                <LeftPanel />
                <Routes>
                  <Route path="/" element={<Main />} />
                  <Route path="/sections" element={<Forums />} />
                  <Route path="/activity" element={<Activity />} />
                  <Route path="/top" element={<Popular />} />
                  <Route path="/random" element={<Random />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/subscriptions" element={<Subscriptions />} />
                  <Route path="/bookmarks" element={<Bookmarks />} />
                  <Route path="/answers" element={<Answers />} />
                  <Route path="/mycomments" element={<MyComments />} />
                  <Route path="/original" element={<Original />} />
                  <Route path="/delayedposts" element={<DelayedPosts />} />
                  <Route path="/mostcommented" element={<MostCommented />} />
                  <Route path="/forums/:forumid" element={<Threads />} />
                  <Route path="/section/:forumid" element={<Section />} />
                  <Route path="/post/:postid" element={<Posts />} />
                  <Route path="/threads/:threadid" element={<Posts />} />
                  <Route path="/user/:userid" element={<User />} />
                  <Route path="/tag/:tag" element={<Tag />} />
                  <Route
                    path="/restorepassword/:confirmid"
                    element={<RestorePassword />}
                  />
                  <Route
                    path="/confirmregistation/:confirmid"
                    element={<ConfirmPassword />}
                  />
                  <Route path="/vklogin" element={<VKLogin />} />
                  <Route path="/search" element={<SearchPage />} />
                  <Route path="/settings" element={<SettingsPage />} />
                  <Route path="/removeaccount" element={<RemoveAccount />} />

                  <Route
                    path="/adscontent"
                    element={<SpecialPage page="adscontent" />}
                  />
                  <Route
                    path="/contest"
                    element={<SpecialPage page="contest" />}
                  />
                  <Route
                    path="/policy"
                    element={<SpecialPage page="policy" />}
                  />

                  <Route path="/:username" element={<User />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
                <RightPanel />
              </div>
              <Overlay />
              <Footer />
            </Login>
          </Translations>
        </Threme>
      </Keys>
      <div id="lgses">
        <div
          className="lgsCloseButton"
          onClick={() => {
            logT("console", "lgs off");
            window.screenLogs = false;
            setCookie("screenlog", false, 30);
            document.getElementById("lgses").style.display = "none";
          }}
        >
          X
        </div>
        <div className="lgsVersion">
          <div className="lgsClient">{process.env.REACT_APP_GIT_DESCRIBE}</div>
        </div>
      </div>
    </div>
  );
}

export default App;
