import React, { useState, useEffect } from "react";
import RecentPosts from "./RecentPosts";

const Main = () => {
  useEffect(() => {
    document.title = `${__("Main page")} - Talkvio`;
  }, []);

  return (
    <div>
      <div className="recentPosts">
        <RecentPosts
          source="main"
          noLimitation={true}
          popular={true}
          cleverPopular={true}
          leftRatingDisplay={true}
        />
      </div>
    </div>
  );
};
export default Main;
