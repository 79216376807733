import React, { createContext, useEffect, useState } from "react";

const Keys = ({ children }) => {
  window.keyBlocking = {};
  window.keyBlockingSources = {};
  window.keyBlockingFull = 0;

  window.blockKeyTag = (tag, source) => {
    if (source) {
      if (window.keyBlockingSources[source]) {
        return;
      }
      window.keyBlockingSources[source] = true;
    }
    if (!window.keyBlocking[tag]) window.keyBlocking[tag] = 1;
    else window.keyBlocking[tag]++;
    logT(
      "key",
      "block keytag",
      tag,
      "count",
      window.keyBlocking[tag],
      "source",
      source,
    );
  };

  window.unblockKeyTag = (tag, source) => {
    if (source) {
      if (!window.keyBlockingSources[source]) {
        return;
      }
      delete window.keyBlockingSources[source];
    }
    if (window.keyBlocking[tag]) {
      window.keyBlocking[tag]--;
      if (window.keyBlocking[tag] <= 0) {
        delete window.keyBlocking[tag];
        logT("key", "unblock keytag", tag);
      } else {
        logT(
          "key",
          "still blocking keytag",
          tag,
          "count",
          window.keyBlocking[tag],
          "source",
          source,
        );
      }
    }
  };

  if (!window.KEY_ON_MAP) window.KEY_ON_MAP = {};

  window.KEY_ON = (key, callback, tag) => {
    if (!window.KEY_ON_MAP[key]) window.KEY_ON_MAP[key] = [];
    window.KEY_ON_MAP[key].push({ callback, tag });
  };

  window.KEY_OFF = (key, callback) => {
    if (!callback) {
      delete window.KEY_ON_MAP[key];
    } else if (window.KEY_ON_MAP[key]?.length > 0) {
      const index = window.KEY_ON_MAP[key].findIndex(
        (bind) => bind.callback == callback,
      );
      if (index > -1) {
        window.KEY_ON_MAP[key].splice(index, 1);
        if (window.KEY_ON_MAP[key].length == 0) delete window.KEY_ON_MAP[key];
      }
    }
  };

  useEffect(() => {
    const keyHandler = (e) => {
      if (window.keyBlockingFull > 0) {
        return;
      }

      const handlers = window.KEY_ON_MAP[e.key];
      if (handlers?.length > 0) {
        handlers.forEach(({ callback, tag }, i) => {
          if (window.keyBlocking[tag]) {
            return;
          }
          logT("key", "call key handler", i, "for key", e.key);
          callback(e);
        });
      }
    };

    document.addEventListener("keypress", keyHandler);
    return () => {
      document.removeEventListener("keypress", keyHandler);
    };
  }, []);

  return <>{children}</>;
};
export default Keys;
