import React from "react";

export default (props) => {
  let className = "tooltip";
  if (props.right) className += " right";
  if (props.top) className += " top";
  const style = {};
  if (props.maxWidth) {
    style.maxWidth = props.maxWidth + "px";
  }
  return (
    <div className={className}>
      {props.children}
      <span className="tooltiptext" style={style}>
        {props.hint}
      </span>
    </div>
  );
};
