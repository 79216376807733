import {
  faCircleCheck,
  faCircleExclamation,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

window.displayError = (obj) => {
  if (obj.error) {
    if (typeof obj.errorDesc == "string") {
      if (obj.errorType == "ok") window.showOk("Ok", obj.errorDesc);
      else if (obj.errorType == "warn")
        window.showWarn("Warning", obj.errorDesc);
      else window.showError("Error", obj.errorDesc);
    } else if (Array.isArray(obj.errorDesc) && obj.errorDesc.length > 0) {
      let message = obj.errorDesc[0].message;
      message = message?.replaceAll(
        "must match format",
        __("wrong value format for field"),
      );
      window.showError("Error", message);
    }
  }
};

const ScreenMessage = () => {
  const [messages, setMessages] = useState([]);

  const messageFun = (title, message, type = "error") => {
    const id =
      (Math.random() + 1).toString(36).substring(7) +
      (Math.random() + 1).toString(36).substring(7);
    const msg = {
      id,
      type,
      title,
      message,
    };
    logT("message-container", "show message", message);
    setMessages([...messages, msg]);
    setTimeout(() => {
      logT("message-container", "remove screen message", message);
      setMessages((messages) => messages.slice(1));
    }, 8000);
  };

  window.showError = (title, message) => messageFun(title, message, "error");
  window.showOk = (title, message) => messageFun(title, message, "ok");
  window.showWarn = (title, message) => messageFun(title, message, "warn");

  return (
    <div
      className={
        "error-container" + (messages.length > 0 ? " show-container" : "")
      }
    >
      {messages.map((message) => (
        <div
          key={message.id}
          className={"error-message status-" + message.type}
        >
          {message.type == "ok" && (
            <FontAwesomeIcon className="error-icon" icon={faCircleCheck} />
          )}
          {message.type == "error" && (
            <FontAwesomeIcon
              className="error-icon"
              icon={faCircleExclamation}
            />
          )}
          {message.type == "warn" && (
            <FontAwesomeIcon
              className="error-icon"
              icon={faTriangleExclamation}
            />
          )}
          <h2 className="error-title">{message.title}</h2>
          <p className="error-desc">{message.message}</p>
        </div>
      ))}
    </div>
  );
};
export default ScreenMessage;
